import React from 'react';

import Record from 'vatix-ui/lib/containers/Record/Record';

import DetailsLoader from 'vatix-ui/lib/components/DetailsLoader';
import { useRouteMatch } from 'react-router-dom';
import { CenteredCircularProgress } from 'vatix-ui/lib/components/Progress';
import { NotFound } from 'vatix-ui/lib/components/Error/Pages';

import DataError from 'vatix-ui/lib/components/Error/DataError';

import { formatDateTime } from 'vatix-ui/lib/utils/formatters/time';

import { DefaultFeedMapping } from 'vatix-ui/lib/components/Feed/Feed';

import { UuidableName } from 'vatix-ui/lib/utils/api/types';

import { observer } from 'mobx-react';

import { isNotFound } from 'utils/api/errors';

import { useNavBarHeight } from 'utils/hooks/navbar';
import { useStore } from 'utils/hooks/store';

import EntityDetailsContent from 'components/Entities/EntityDetailsContent';

import EntityDetailsHeader from 'components/Entities/EntityDetailsHeader';
import EntityDetailsActivities from 'components/Entities/EntityDetailsActivities';
import EntityDetailsActivitiesFooter from 'components/Entities/EntityDetailsActivities/EntityDetailsActivitiesFooter';

import { ActivityMapping } from 'utils/api/types';
import UserField from 'containers/IncidentDetails/components/UserField';
import { ActivityType } from 'utils/stores/Activities/constants';

import { EntityDropdownFieldProps } from 'containers/IncidentDetails/components/types';

import EventState from './EventActivities/EventState';
import EventName from './EventActivities/EventName';
import EventOwner from './EventActivities/EventOwner';

const activityTypeToComponent = ({
  ...DefaultFeedMapping,
  [ActivityType.EventState]: EventState,
  [ActivityType.EventOwnerChange]: EventOwner,
  [ActivityType.EventNameChange]: EventName,
} as unknown) as ActivityMapping;

const EventDetails: React.FunctionComponent = (): React.ReactElement => {
  const navBarHeight = useNavBarHeight();
  const match = useRouteMatch<{ entityId: string }>();

  const { eventDetails } = useStore();

  return (
    <DetailsLoader
      match={match}
      routeParam="entityId"
      // @ts-ignore
      store={eventDetails}
    >
      {({ isLoaded, error, reload }): React.ReactElement => {
        if (!isLoaded) {
          return <CenteredCircularProgress />;
        }
        if (eventDetails.details === undefined) {
          return <NotFound />;
        }
        if (error) {
          if (isNotFound(error)) {
            return <NotFound />;
          }
          return <DataError onReload={reload} />;
        }

        const {
          prefix,
          details: { entity },
        } = eventDetails;

        const eventReporter = entity[`${prefix}eventReporter`];
        const eventOwner = entity[`${prefix}eventOwner`];
        const eventName = entity[`${prefix}eventName`];
        const eventType = entity[`${prefix}eventType`] as EntityDropdownFieldProps;

        return (
          <Record
            navBarHeight={navBarHeight}
            Header={
              <EntityDetailsHeader
                store={eventDetails}
                title={eventName.value as string}
                columns={[
                  {
                    name: 'Reporter',
                    value: eventReporter.value ? (
                      <UserField userId={(eventReporter.value as UuidableName).uuid} />
                    ) : (
                      <p>N/A</p>
                    ),
                  },
                  {
                    name: 'Created',
                    value: <p>{formatDateTime(eventDetails.details.createdAt)}</p>,
                  },
                  {
                    name: 'Type',
                    value: eventType.value ? <p>{eventType.value[0].value}</p> : <p>N/A</p>,
                  },
                  {
                    name: 'Owner',
                    value: eventOwner.value ? (
                      <UserField userId={(eventOwner.value as UuidableName).uuid} />
                    ) : (
                      <p>N/A</p>
                    ),
                  },
                ]}
              />
            }
            Content={(onError) => <EntityDetailsContent onError={onError} store={eventDetails} />}
            Activities={{
              Content: (
                <EntityDetailsActivities
                  activities={eventDetails.activities}
                  activityTypeToComponent={activityTypeToComponent}
                />
              ),
              Footer: <EntityDetailsActivitiesFooter activities={eventDetails.activities} />,
            }}
          />
        );
      }}
    </DetailsLoader>
  );
};

export default observer(EventDetails);
