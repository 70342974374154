import { action, observable, makeObservable } from 'mobx';
import { AxiosError } from 'axios';

import DataMixin from 'vatix-ui/lib/utils/stores/DataMixin';

import { UniqueIdentifier } from '@dnd-kit/core';

import { v4 as uuid } from 'uuid';

import API from 'utils/api';
import RootStore from 'stores/Root';

import { FormBuilderType } from 'utils/api/types';

import FormBuilderSettings from 'stores/FormBuilderSettings/FormBuilderSettings';

import FormBuilderDragAndDrop from './helpers/FormBuilderDragAndDrop';
import FormBuilderValidation from './helpers/FormBuilderValidation';
import FormBuilderDataManager from './helpers/FormBuilderDataManager';
import SectionLogicStore from './helpers/SectionLogicStore';
import QuestionLogicStore from './helpers/QuestionLogicStore';
import FormAction from './helpers/FormAction';

type Items = Record<UniqueIdentifier, UniqueIdentifier[]>;

export default class FormBuilder extends DataMixin<typeof API, RootStore> {
  @observable error?: AxiosError;

  @observable data?: FormBuilderType;

  // data loaded at first, needed to compare with the current data
  @observable loadedData?: FormBuilderType;

  @observable lastPublished?: string;

  @observable containers: string[] = [];

  @observable items: Items = {};

  @observable contentOfLayoutWasEdited = false;

  @observable formName = '';

  @observable formId = 'new';

  @observable currentEditedQuestion: { sectionId: string; fieldId: string } | undefined = undefined;

  // variable needed to block main click away event when editing dropdown options
  @observable isDropdownOptionInEdit: string | undefined = undefined;

  // helper stores
  dragAndDropManager: FormBuilderDragAndDrop;

  validation: FormBuilderValidation;

  dataManager: FormBuilderDataManager;

  sectionLogic: SectionLogicStore;

  questionLogic: QuestionLogicStore;

  formBuilderSettings: FormBuilderSettings;

  formAction: FormAction;

  // variable to display UI for conditional logic
  @observable dropdownConditionalLogic: { sectionId: string; fieldId: string } | undefined = undefined;

  constructor(rootStore: RootStore, api: typeof API) {
    super(rootStore, api);

    // Initialize helper stores
    this.dragAndDropManager = new FormBuilderDragAndDrop(this);

    this.validation = new FormBuilderValidation(rootStore, this);

    this.dataManager = new FormBuilderDataManager(rootStore, api, this);

    this.sectionLogic = new SectionLogicStore(this);

    this.questionLogic = new QuestionLogicStore(this);

    this.formBuilderSettings = new FormBuilderSettings(rootStore, api);

    this.formAction = new FormAction(this);

    makeObservable(this);
  }

  getNextContainerId = (): string => uuid().slice(0, 8);

  @action.bound
  setDropdownOptionInEdit(value: string | undefined): void {
    this.isDropdownOptionInEdit = value;
  }

  setDropdownConditionalLogic = (value: { sectionId: string; fieldId: string } | undefined): void => {
    this.dropdownConditionalLogic = value;
  };

  @action.bound
  setCurrentEditedQuestion(data: { sectionId: string; fieldId: string } | undefined): void {
    this.currentEditedQuestion = data;
  }

  @action.bound
  updateFormName(name: string): void {
    this.formName = name;
  }

  getAllSectionTitlesAndKeys = (currentSectionId: string): { key: string; title: string }[] => {
    if (!this.data) return [];

    // return all sections AFTER the current section
    const currentSectionIndex = this.containers.indexOf(currentSectionId);
    const sectionsAfterCurrentSection = this.containers.slice(currentSectionIndex + 1);

    return sectionsAfterCurrentSection.map((container) => ({
      key: container,
      title: this.data!.properties[container].title,
    }));
  };

  @action.bound
  resetForm(): void {
    this.data = undefined;
    this.loadedData = undefined;
    this.lastPublished = undefined;
    this.containers = [];
    this.items = {};
    this.formName = '';
    this.currentEditedQuestion = undefined;
    this.contentOfLayoutWasEdited = false;
  }
}
