import { action, makeObservable, observable } from 'mobx';

import { FormActionsType, FormActionType } from 'utils/api/types';

import FormBuilder from '../FormBuilder';

// FORM ACTIONS - conditional logic for submitting the form

class FormAction {
  @observable formActions: { [sectionKey: string]: FormActionsType } = {};

  constructor(private formBuilder: FormBuilder) {
    makeObservable(this);
  }

  // Helper function to create a new action
  createNewAction(when: FormActionType['when'], then: FormActionType['then']): FormActionType {
    return { when, then };
  }

  // Helper function to filter actions by question key
  filterActionsByQuestionKey(actions: FormActionType[], questionKey: string): FormActionType[] {
    return actions.filter(({ when }) =>
      typeof when === 'object' && 'equals' in when ? when.equals[0] === questionKey : false
    );
  }

  // Helper function to remove actions by question key
  removeActionsByQuestionKey(actions: FormActionType[], questionKey: string): FormActionType[] {
    return actions.filter(({ when }) => when !== true && typeof when === 'object' && when.equals[0] !== questionKey);
  }

  // Helper function to remove actions by section key
  removeActionsBySectionKey(actions: FormActionType[], sectionKey: string): FormActionType[] {
    return actions.filter((ac) => ('nextSection' in ac.then ? ac.then.nextSection !== sectionKey : true));
  }

  // get the default value for a section
  @action.bound
  getSectionDefaultAction(sectionKey: string): string {
    if (!this.formActions) {
      return 'next';
    }

    const defaultAction = this.formActions[sectionKey]?.find((ac) => ac.when === true);

    if (defaultAction) {
      if ('nextSection' in defaultAction.then) {
        return defaultAction.then.nextSection;
      }
      return 'submit';
    }

    return 'next';
  }

  // set the default action for a section
  @action.bound
  setSectionDefaultAction(sectionKey: string, nextSection: string): void {
    if (!this.formActions) {
      this.formActions = {};
    }

    const nextSectionId =
      nextSection === 'next'
        ? this.formBuilder.containers[this.formBuilder.containers.indexOf(sectionKey) + 1]
        : nextSection;

    const newAction: FormActionType = {
      when: true,
      then: nextSection === 'submit' ? { submit: true } : { nextSection: nextSectionId },
    };

    const existingDefaultAction = this.formActions[sectionKey]?.find((ac) => ac.when === true);
    if (existingDefaultAction) {
      this.formActions[sectionKey] = this.formActions[sectionKey].filter((ac) => ac.when !== true);
      this.formActions[sectionKey].push(newAction);
    } else if (this.formActions[sectionKey]) {
      this.formActions[sectionKey].push(newAction);
    } else {
      this.formActions[sectionKey] = [newAction];
    }
  }

  @action.bound
  duplicateQuestionActions(sectionKey: string, questionKey: string, newQuestionKey: string): void {
    if (!this.formActions) {
      return;
    }

    const sectionAction = this.formActions[sectionKey];
    if (!sectionAction) {
      return;
    }

    const actions = this.filterActionsByQuestionKey(sectionAction, questionKey);
    if (!actions.length) {
      return;
    }

    actions.forEach(({ when, then }) => {
      if (typeof when === 'boolean') {
        return;
      }

      const newWhen: [string, string] = [newQuestionKey, when.equals[1]];
      const newAction = this.createNewAction({ equals: newWhen }, then);

      this.formActions[sectionKey].push(newAction);
    });
  }

  @action.bound
  removeQuestionActions(sectionKey: string, questionKey: string): void {
    if (!this.formActions) {
      return;
    }

    this.formActions[sectionKey] = this.removeActionsByQuestionKey(this.formActions[sectionKey], questionKey);
  }

  @action.bound
  removeSectionActions(sectionKey: string): void {
    if (!this.formActions) {
      return;
    }

    delete this.formActions[sectionKey];

    Object.keys(this.formActions).forEach((key) => {
      this.formActions[key] = this.removeActionsBySectionKey(this.formActions[key], sectionKey);
    });
  }

  // - moving question from one section to another - update the actions = move actions from one section to another
  @action.bound
  moveQuestionActions(activeSectionKey: string, overSectionKey: string, questionKey: string): void {
    if (!this.formActions) {
      return;
    }

    const activeSection = this.formActions[activeSectionKey];

    if (!activeSection) {
      return;
    }

    const activeActions = activeSection.filter(
      ({ when }) => typeof when === 'object' && when.equals[0] === questionKey
    );

    if (!activeActions) {
      return;
    }

    activeActions.forEach(({ when, then }) => {
      const value = typeof when === 'object' ? when.equals[1] : '';
      const newAction: FormActionType = {
        when: {
          equals: [questionKey, value],
        },
        then,
      };

      if (this.formActions[overSectionKey]) {
        this.formActions[overSectionKey].push(newAction);
      } else {
        this.formActions[overSectionKey] = [newAction];
      }
    });

    this.formActions[activeSectionKey] = activeSection.filter(
      ({ when }) => typeof when === 'object' && when.equals[0] !== questionKey
    );
  }

  @action.bound
  updateFormActions(sectionKey: string, questionKey: string, questionValue: string, nextSection: string): void {
    if (!this.formActions) {
      this.formActions = {};
    }

    // Get existing actions or initialize new array
    const actions = this.formActions[sectionKey] || [];

    // Find existing action for this question/value combination
    const actionIndex = actions.findIndex(
      ({ when }) =>
        when !== true &&
        typeof when === 'object' &&
        'equals' in when &&
        when.equals[0] === questionKey &&
        when.equals[1] === questionValue
    );

    // Handle "next" section logic
    let targetSection: string = nextSection;
    if (nextSection === 'next') {
      const { containers } = this.formBuilder;
      if (!containers || !containers.length) {
        targetSection = 'submit';
      } else {
        const currentIndex = containers.indexOf(sectionKey);
        targetSection =
          currentIndex >= 0 && currentIndex < containers.length - 1 ? containers[currentIndex + 1] : 'submit';
      }
    }

    // Create new action based on nextSection value
    const newAction: FormActionType = {
      when: {
        equals: [questionKey, questionValue],
      },
      then: nextSection === 'submit' ? { submit: true } : { nextSection: targetSection },
    };

    // Update or add the action
    if (actionIndex >= 0) {
      this.formActions[sectionKey][actionIndex] = newAction;
    } else {
      if (!this.formActions[sectionKey]) {
        this.formActions[sectionKey] = [];
      }
      this.formActions[sectionKey].push(newAction);
    }
  }

  getActionsForQuestion = (sectionKey: string, questionKey: string): FormActionsType => {
    if (!this.formActions) {
      return [];
    }
    return (
      this.formActions[sectionKey]?.filter(({ when }) => typeof when === 'object' && when.equals[0] === questionKey) ||
      []
    );
  };

  getActionForQuestionAndValue = (sectionKey: string, questionKey: string, questionValue: string): string => {
    if (!this.formActions) {
      return '';
    }

    const value = this.formActions[sectionKey]?.find(
      ({ when }) =>
        typeof when === 'object' &&
        'equals' in when &&
        when.equals[0] === questionKey &&
        when.equals[1] === questionValue
    );

    // if the value.then.nextSection is the key of the next section in the order, return `next`
    const nextSectionKey = this.formBuilder.containers[this.formBuilder.containers.indexOf(sectionKey) + 1];
    if (value && 'nextSection' in value.then && value.then.nextSection === nextSectionKey) {
      return 'next';
    }

    if (value && 'submit' in value.then) {
      return 'submit';
    }

    return (value && 'nextSection' in value.then && value?.then.nextSection) || nextSectionKey;
  };
}

export default FormAction;
